import { Component, ElementRef, Input, Optional, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { fadeAnimation } from 'apps/early-stage-office/src/app/core/animations';
import { ModalRef } from 'apps/early-stage-office/src/app/utility-modules/modals/services/modal-ref';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { LayoutState } from 'apps/early-stage-office/src/app/core/store/layout/layout.state';
import { TErrorMessage } from '../../../core/services/validation-messages.service';
import { ButtonComponent } from '../../../utility-modules/es-form/components/button/button.component';
import { EFormState, TooltipDirective } from '@early-stage/components';
import { ModalFooterDirective } from '../../../utility-modules/modals/directives/modal-footer.directive';
import { FormErrorComponent } from '../../../utility-modules/es-form/components/form-error/form-error.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { ModalContentDirective } from '../../../utility-modules/modals/directives/modal-content.directive';
import { ModalHeaderDirective } from '../../../utility-modules/modals/directives/modal-header.directive';
import { map } from 'rxjs/operators';
import { isArray } from 'lodash';

@Component({
    selector: 'es-form-in-modal',
    templateUrl: './form-in-modal.component.html',
    styleUrls: ['./form-in-modal.component.scss'],
    animations: [fadeAnimation],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        ModalHeaderDirective,
        ModalContentDirective,
        NgIf,
        FormErrorComponent,
        ModalFooterDirective,
        TooltipDirective,
        ButtonComponent,
        AsyncPipe,
    ],
})
export class FormInModalComponent {
    @Input() public formGroup: UntypedFormGroup;
    @Input() public submitText: string = 'Dodaj';
    @Input() public errorObservable: Observable<TErrorMessage[] | TErrorMessage>;
    public singleErrorObservable: Observable<TErrorMessage>;
    @Input() public formState: EFormState;
    @Input() public showCancelOnMobile: boolean = true;
    @Input() public disabled: boolean = false;
    @Input() public disabledTooltip: string = '';

    @Select(LayoutState.isMobile) public isMobile$: Observable<boolean>;

    @ViewChild('submitContainer', { static: false }) public submitContainer: ElementRef;

    constructor(@Optional() private modalRef: ModalRef) {}

    public ngOnInit(): void {
        this.singleErrorObservable = this.errorObservable?.pipe(map(v => (isArray(v) ? v[0] : v)));
    }

    public close(): void {
        this.modalRef.close();
    }
}
