import { Directive } from '@angular/core';

@Directive({
    selector: '[esModalHeader]',
    exportAs: 'esModalHeader',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'modal__header',
    },
    standalone: true,
})
export class ModalHeaderDirective {
    constructor() {}
}
