<nav class="nav {{ classModifiers }}">
    <ul class="nav__list" *ngIf="navigationItems">
        <ng-container *ngFor="let item of navigationItems">
            <li class="nav__item" *ngIf="item.hideMobile && (isMobile$ | async) ? false : true">
                <es-navigation-item [modifiers]="modifiers" [item]="item"></es-navigation-item>
            </li>
        </ng-container>
        <li class="nav__item" *ngIf="(isMobile$ | async) && showSupportButton()">
            <es-support-navigation-item [modifiers]="modifiers"></es-support-navigation-item>
        </li>
    </ul>
</nav>
