import { Injectable } from '@angular/core';
import { ApiClientService } from './api.service';
import { IFranchiseAddDTO, IFranchiseEnrollStatistics, IFranchiseListItem, IFranchiseTag, ISingleFranchise } from '../models/franchise.interface';
import { TArchived } from 'apps/early-stage-office/src/app/core/models/archived.type';
import { Observable, throwError } from 'rxjs';
import { IResults } from 'apps/early-stage-office/src/app/core/models/results.interface';
import { map } from 'rxjs/operators';
import {
    IFranchiseCompanyData,
    IFranchiseData,
    IFranchiseSurveyData,
    IFranchiseYearlySettings,
    IFranchiseYearlySettingsAdvance,
    IFranchiseYearlySettingsAgreement,
    IFranchiseYearlySettingsDiscounts,
    IFranchiseYearlySettingsEscort,
    IFranchiseYearlySettingsOfr,
    IFranchiseYearlySettingsPaymentDays,
    IFranchiseYearlySettingsVindication,
} from '../models/franchise-data.interface';

@Injectable({
    providedIn: 'root',
})
export class FranchiseService {
    public path: string = 'franchises';
    constructor(private api: ApiClientService) {}

    public getPath() {
        return `franchises`;
    }

    public get(params: { [key: string]: unknown } = {}): Observable<IFranchiseListItem[]> {
        return this.api
            .get<IResults<IFranchiseListItem[]>>(this.getPath() + '/plain', { params })
            .pipe(map(dtos => dtos.results.map(item => this.parse(item))));
    }

    public getArchive(params?: { [key: string]: unknown }): Observable<TArchived<IFranchiseListItem>[]> {
        return this.api
            .get<IResults<TArchived<IFranchiseListItem>[]>>(this.getPath() + '/archive/plain', { params })
            .pipe(map(dtos => dtos.results.map(item => this.parse(item))));
    }

    public getOne(id: number): Observable<ISingleFranchise> {
        return this.api.get<IResults<ISingleFranchise>>(this.getPath() + '/' + id).pipe(map(dto => dto.results));
    }

    public getOneEnrollCounters(id: number): Observable<IFranchiseEnrollStatistics> {
        return this.api.get<IResults<IFranchiseEnrollStatistics>>(this.getPath() + '/' + id + '/counters/enroll').pipe(map(dto => dto.results));
    }

    public editYearlySettingsAgreement(id: number, yearId: number, data: IFranchiseYearlySettingsAgreement): Observable<IFranchiseYearlySettings> {
        return this.api
            .patch<IResults<IFranchiseYearlySettings>>(this.getPath() + '/' + id + '/data/' + yearId + '/settings/agreement', data)
            .pipe(map(dto => dto.results));
    }

    public editYearlySettingsPayments(id: number, yearId: number, data: IFranchiseYearlySettingsPaymentDays): Observable<IFranchiseYearlySettings> {
        return this.api
            .patch<IResults<IFranchiseYearlySettings>>(this.getPath() + '/' + id + '/data/' + yearId + '/settings/payments', data)
            .pipe(map(dto => dto.results));
    }

    public editYearlySettingsVindication(id: number, yearId: number, data: IFranchiseYearlySettingsVindication): Observable<IFranchiseYearlySettings> {
        return this.api
            .patch<IResults<IFranchiseYearlySettings>>(this.getPath() + '/' + id + '/data/' + yearId + '/settings/vindication', data)
            .pipe(map(dto => dto.results));
    }
    public editYearlySettingsDiscounts(id: number, yearId: number, data: IFranchiseYearlySettingsDiscounts): Observable<IFranchiseYearlySettings> {
        return this.api
            .patch<IResults<IFranchiseYearlySettings>>(this.getPath() + '/' + id + '/data/' + yearId + '/settings/discounts', data)
            .pipe(map(dto => dto.results));
    }
    public editYearlySettingsEscort(id: number, yearId: number, data: IFranchiseYearlySettingsEscort): Observable<IFranchiseYearlySettings> {
        return this.api
            .patch<IResults<IFranchiseYearlySettings>>(this.getPath() + '/' + id + '/data/' + yearId + '/settings/escort', data)
            .pipe(map(dto => dto.results));
    }
    public editYearlySettingsAdvance(id: number, yearId: number, data: IFranchiseYearlySettingsAdvance): Observable<IFranchiseYearlySettings> {
        return this.api
            .patch<IResults<IFranchiseYearlySettings>>(this.getPath() + '/' + id + '/data/' + yearId + '/settings/advance', data)
            .pipe(map(dto => dto.results));
    }
    public editYearlySettingsOfr(id: number, yearId: number, data: IFranchiseYearlySettingsOfr): Observable<IFranchiseYearlySettings> {
        return this.api
            .patch<IResults<IFranchiseYearlySettings>>(this.getPath() + '/' + id + '/data/' + yearId + '/settings/cooperation', data)
            .pipe(map(dto => dto.results));
    }

    public getData(id: number): Observable<IFranchiseData> {
        return this.api.get<IResults<IFranchiseData>>(this.getPath() + '/' + id + '/data').pipe(map(dto => dto.results));
    }

    public editCompanyData(id: number, data: IFranchiseCompanyData): Observable<IFranchiseData> {
        return this.api.patch<IResults<IFranchiseData>>(this.getPath() + '/' + id + '/data/company', data).pipe(map(dto => dto.results));
    }

    public editSurveyData(id: number, data: IFranchiseSurveyData): Observable<IFranchiseData> {
        return this.api.patch<IResults<IFranchiseData>>(this.getPath() + '/' + id + '/data/survey', data).pipe(map(dto => dto.results));
    }

    public editTags(id: number, tags: IFranchiseTag[]): Observable<ISingleFranchise> {
        return this.api.patch<IResults<ISingleFranchise>>(this.getPath() + '/' + id + '/data/tags', { tags }).pipe(map(dto => dto.results));
    }

    public create(data: IFranchiseAddDTO): Observable<IFranchiseListItem> {
        return this.api.post<IResults<IFranchiseListItem>>(this.getPath(), data).pipe(map(response => this.parse(response.results)));
    }

    public edit(data: IFranchiseAddDTO): Observable<IFranchiseListItem> {
        return this.api.patch<IResults<IFranchiseListItem>>(this.getPath() + '/' + data.id, data).pipe(map(response => this.parse(response.results)));
    }

    public archive(id: number): Observable<void> {
        return this.api.patch<IResults<IFranchiseListItem>>(this.getPath() + '/' + id + '/archive').pipe(map(() => undefined));
    }

    public delete(id: number): Observable<void> {
        return this.api.delete<IResults<IFranchiseListItem>>(this.getPath() + '/' + id).pipe(map(() => undefined));
    }

    public restore(id: number): Observable<IFranchiseListItem> {
        return this.api.patch<IResults<IFranchiseListItem>>(this.getPath() + '/' + id + '/restore').pipe(map(response => this.parse(response.results)));
    }

    public deleteAt(id: number, deleteAt: string): Observable<IFranchiseListItem> {
        if (!id) {
            return throwError('id is required');
        }

        return this.api
            .patch<IResults<IFranchiseListItem>>(this.getPath() + '/' + id + '/deleteAt', { deleteAt })
            .pipe(map(response => this.parse(response.results)));
    }

    public parse(franchiseeDto: TArchived<IFranchiseListItem>): TArchived<IFranchiseListItem>;
    public parse(franchiseeDto: IFranchiseListItem): IFranchiseListItem;
    public parse(franchiseeDto) {
        const dto = franchiseeDto as TArchived<IFranchiseListItem>;

        if (!dto || !dto.id) {
            return null;
        }

        const franchiseData = {
            id: dto.id,
            province: dto.province,
            franchiseUsers: dto.franchiseUsers,
            agencies: dto.agencies,
            isTest: dto.isTest,
        } as IFranchiseListItem & TArchived<IFranchiseListItem>;

        if (dto.archivedBy) {
            franchiseData.archivedAt = dto.archivedAt;
            franchiseData.deleteAt = dto.deleteAt;
            franchiseData.archivedBy = dto.archivedBy;
        }

        return franchiseData;
    }
}
