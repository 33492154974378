<div class="settings-modal">
    <div class="settings-modal__header">
        <div class="settings-modal__title settings-title">
            <div class="settings-title__image-container">
                <span class="settings-title__image">
                    <img
                        alt=""
                        [src]="'/assets/images/support/link-settings.png'"
                        [srcset]="'/assets/images/support/link-settings@2x.png 2x, /assets/images/support/link-settings@3x.png 3x'" />
                </span>
            </div>
            <span class="settings-title__text">Ustawienia</span>
        </div>
    </div>

    <div class="settings-modal__links">
        <button class="settings-link link link--text" (click)="openCookiesSettingsModal()">Preferencja plików cookies</button>
        <a href="/assets/files/Regulamin ES Office.pdf" class="settings-link link link--text" target="_blank">Regulamin</a>
        <a href="/assets/files/Polityka Prywatnosci ES Office.pdf" class="settings-link link link--text" target="_blank">Polityka prywatności</a>
    </div>

    <div class="settings-modal__footer">
        <div class="settings-versions">
            <p *ngIf="appVersion$ | async as appVersion">Wersja: {{ appVersion }}</p>
            <p *ngIf="apiVersion$ | async as apiVersion">Wersja API: {{ apiVersion }}</p>
        </div>
    </div>
</div>
