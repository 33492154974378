import { Directive } from '@angular/core';

@Directive({
    selector: '[esModalContent]',
    exportAs: 'esModalContent',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'modal__content esc-modal__content',
    },
    standalone: true,
})
export class ModalContentDirective {
    constructor() {}
}
