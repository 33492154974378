<form [formGroup]="formGroup">
    <div esModalHeader>
        <ng-content select="[modal-header]"></ng-content>
    </div>
    <div class="relative">
        <div esModalContent>
            <div class="modal__loading" *ngIf="formState === 'loading'">
                <span class="modal-spinner" aria-label="Loading" [@fadeAnimation]></span>
            </div>

            <ng-content select="[modal-content]"></ng-content>
            <ng-content select="[modal-form-content]"></ng-content>
            <es-form-error [errorObservable]="singleErrorObservable"></es-form-error>
        </div>
        <div esModalFooter>
            <div class="modal__wrapper">
                <div class="group is-space-between">
                    <div *ngIf="!(isMobile$ | async) || showCancelOnMobile">
                        <button type="button" class="link is-underline" (click)="close()">Anuluj</button>
                    </div>
                    <div class="form-buttons">
                        <ng-content select="[additional-buttons]"></ng-content>
                        <div #submitContainer class="relative" [escTooltip]="disabledTooltip" [escTooltipEnabled]="disabled">
                            <es-button
                                id="submit"
                                [modifiers]="['small']"
                                class="form-buttons__button"
                                [useLoader]="false"
                                [formState]="formState"
                                [disabled]="disabled"
                                >{{ submitText }}</es-button
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal__overlay" *ngIf="formState === 'loading'" [@fadeAnimation]></div>
    </div>
</form>
