import { Directive } from '@angular/core';

@Directive({
    selector: '[esModalFooter]',
    exportAs: 'esModalFooter',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'modal__footer',
    },
    standalone: true,
})
export class ModalFooterDirective {
    constructor() {}
}
